<template>
  <div class="container">
    <img class="poster" src="@/assets/images/EndoscopeCompetition.jpg" />
    <div class="videoBox">
      <video
        id="player-container-id"
        style="width: 100%; height: 100%;"
        preload="auto"
        playsinline=""
        webkit-playsinline=""
        x5-playsinline=""
      ></video>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: 'EndoscopeChallengePoster',
  data() {
    return {
      player: null,
      videoInfo: null
    }
  },
  computed: {
    ...mapState(["userInfo", "Openid"]),
  },
  mounted() {
    this.getVideo();
  },
  methods: {
    // 初始化播放器
    initVideo() {
      // if (!this.player) {
      //   this.player = this.TCPlayer('player-container-id', {
      //     loop: true,
      //     poster: 'https://wcwechat.jjmc.cn/Api/UploadImg/a6ccc68b4e224611ba7104de70075ef9.png',
      //     licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1307224350_1/v_cube.license'
      //   }).player;
      //   this.player.src('https://1500012405.vod2.myqcloud.com/a2881783vodtranssh1500012405/3f8c0c263270835010027974977/v.f100030.mp4');
      // }
      let Options = {};
      if(this.videoInfo && this.videoInfo.fileid) {
        Options = {
          loop: true,
          poster: 'https://wcwechat.jjmc.cn/Api/UploadImg/a6ccc68b4e224611ba7104de70075ef9.png',
          fileID: this.videoInfo.fileid,
          appID: this.videoInfo.appid,
          psign: this.videoInfo.psign,
          licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1307224350_1/v_cube.license'
        };
        this.player = this.TCPlayer('player-container-id', Options, {
          app_Play_Id: this.videoInfo.app_Play_Id,
          app_Id: 'wcwechat',
          user_Id: this.videoInfo.user_Id,
          video_Id: 0
        },{
          Id: this.userInfo.Id,
          RealName: this.userInfo.RealName,
          HospitalTitle: this.userInfo.HospitalTitle,
          PositionalName: this.userInfo.PositionalName,
          SubjectName: this.userInfo.SubjectName,
          Phone: this.userInfo.Phone
        }, this.videoInfo.Name, this.videoInfo.videoSize, this.videoInfo.fileid).player;
      } else {
        Options = {
          loop: true,
          poster: 'https://wcwechat.jjmc.cn/Api/UploadImg/a6ccc68b4e224611ba7104de70075ef9.png',
          licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1307224350_1/v_cube.license'
        };
        this.player = this.TCPlayer("player-container-id", Options).player;
        this.player.src('https://1500012405.vod2.myqcloud.com/a2881783vodtranssh1500012405/3f8c0c263270835010027974977/v.f100030.mp4');
      }
    },
    getVideo() {
      this.$axios.get('/Api/api/Web/Article/GetUI?vfid=3270835010027974977').then(res => {
        this.videoInfo = res.Data;
        this.initVideo();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  .poster {
    display: block;
    width: 100%;
  }
  .videoBox {
    width: 90%;
    height: 195px;
    position: absolute;
    left: 5%;
    bottom: 4.5%;
    video {
      width: 100%;
    }
  }
}
</style>